"use client";

import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";

export function useStripePromiseForConnectedAccount(stripeAccount: string) {
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    const fetchStripePromise = async () => {
      if (stripeAccount) {
        const res = loadStripe(
          process.env.NEXT_PUBLIC_STRIPE_CONNECT_PUBLISHABLE_KEY ?? "",
          {
            stripeAccount,
          },
        );

        setStripePromise(res);
      }
    };
    fetchStripePromise();
  }, [stripeAccount]);

  return stripePromise;
}
