import { ReactNode } from "react";

export interface ChatFilledProps {
  width?: string;
  height?: string;
  className?: string;
  fill?: string;
}

export function ChatFilled(props: ChatFilledProps): ReactNode {
  const { width, height, fill } = props;
  return (
    <svg
      width={width ? width : "14"}
      height={height ? height : "15"}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 0.75H12.25C13.207 0.75 14 1.54297 14 2.5V10.375C14 11.3594 13.207 12.125 12.25 12.125H8.44922L5.05859 14.668C4.92188 14.7773 4.75781 14.7773 4.59375 14.7227C4.45703 14.6406 4.375 14.5039 4.375 14.3125V12.125H1.75C0.765625 12.125 0 11.3594 0 10.375V2.5C0 1.54297 0.765625 0.75 1.75 0.75Z"
        fill={fill ? fill : "white"}
      />
    </svg>
  );
}
