import { ReactNode } from "react";

export interface CircledCheckProps {
  width?: string;
  height?: string;
  className?: string;
  fill?: string;
}

export function CircledCheck(props: CircledCheckProps): ReactNode {
  const { width, height, fill, className = "" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "12"}
      height={height ? height : "12"}
      viewBox="0 0 12 12"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_2420_1762)">
        <path
          d="M6 0C2.67606 0 0 2.67606 0 6C0 9.32394 2.67606 12 6 12C9.32394 12 12 9.32394 12 6C12 2.67606 9.32394 0 6 0ZM6 10.8169C3.35211 10.8169 1.1831 8.64789 1.1831 6C1.1831 3.35211 3.35211 1.1831 6 1.1831C8.64789 1.1831 10.8169 3.35211 10.8169 6C10.8169 8.64789 8.64789 10.8169 6 10.8169ZM8.76056 3.35211L4.78873 7.29577L3.23944 5.74648L2.39437 6.59155L4.78873 9.01408L9.60563 4.19718L8.76056 3.35211Z"
          fill={fill ? fill : "#9AA893"}
        />
      </g>
      <defs>
        <clipPath id="clip0_2420_1762">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
