"use client";
type CustomImageLoaderParams = {
  src: string;
  width: number;
  quality?: number;
};

/*
Custom image loader that is used by next/images to load the image in an optimized way.
The width, quality, and format are set in the img src URL search params.
Helpful for images from Contentful CDN and images.yardzen.co.
Referenced in next.config.js.
*/

export function customImageLoader({
  src,
  width,
  quality,
}: CustomImageLoaderParams) {
  const params = new URLSearchParams();

  params.set("w", width.toString());

  params.set("q", (quality || 75).toString());

  params.set("fm", "webp");

  params.set("fit", "fill");

  return `${src}?${params.toString()}`;
}

export default customImageLoader;
