import lawnRemovalIcon from "./lawn-removal.svg";
import lawnInstallationIcon from "./lawn-installation-grass.svg";
import turfInstallationIcon from "./lawn-installation-turf.svg";
import plantingIcon from "./new-plants.svg";
import raisedBedsVegetableGardenIcon from "./raised-beds-vegetable-garden.svg";
import treeRemovalIcon from "./tree-removal.svg";
import treesIcon from "./new-trees-and-or-shrubs.svg";
import poolIcon from "./pool.svg";
import hotTubIcon from "./hot-tub.svg";
import outdoorShowerIcon from "./outdoor-shower.svg";
import fountainIcon from "./fountain.svg";
import outdoorKitchenIcon from "./outdoor-kitchen.svg";
import bbqAreaIcon from "./outdoor-kitchen.svg";
import firePitIcon from "./fire-pit.svg";
import outdoorPlayAreaIcon from "./outdoor-play-area.svg";
import deckIcon from "./deck.svg";
import pergolaIcon from "./pergola.svg";
import shedIcon from "./shed.svg";
import aduIcon from "./adu.svg";
import retainingWallIcon from "./retaining-wall.svg";
import patioIcon from "./patio.svg";
import outdoorLightingIcon from "./outdoor-lighting.svg";
import fencingIcon from "./fences.svg";
import gatesIcon from "./gates.svg";
import drivewayIcon from "./driveway.svg";
import pathwaysIcon from "./pathways.svg";
import paintingIcon from "./painting.svg";
import sidingReplacementIcon from "./siding-replacement.svg";
import doorsReplacementIcon from "./doors-replacement.svg";
import windowsReplacementIcon from "./windows-replacement.svg";
import roofingIcon from "./roofing.svg";

export {
  lawnRemovalIcon,
  lawnInstallationIcon,
  turfInstallationIcon,
  plantingIcon,
  raisedBedsVegetableGardenIcon,
  treeRemovalIcon,
  treesIcon,
  poolIcon,
  hotTubIcon,
  outdoorShowerIcon,
  fountainIcon,
  outdoorKitchenIcon,
  bbqAreaIcon,
  firePitIcon,
  outdoorPlayAreaIcon,
  deckIcon,
  pergolaIcon,
  shedIcon,
  aduIcon,
  retainingWallIcon,
  patioIcon,
  outdoorLightingIcon,
  fencingIcon,
  gatesIcon,
  drivewayIcon,
  pathwaysIcon,
  paintingIcon,
  sidingReplacementIcon,
  doorsReplacementIcon,
  windowsReplacementIcon,
  roofingIcon,
};
