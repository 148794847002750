import { ReactNode } from "react";

export interface DownloadProps {
  width?: string;
  height?: string;
  className?: string;
}

export function Download(props: DownloadProps): ReactNode {
  return (
    <svg
      width="24"
      height="24"
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5517 14.4751C21.3528 14.4751 21.162 14.5541 21.0214 14.6948C20.8807 14.8354 20.8017 15.0262 20.8017 15.2251V17.7863C20.8017 18.39 20.5619 18.969 20.135 19.3959C19.7081 19.8228 19.1292 20.0626 18.5255 20.0626H5.47547C4.87177 20.0626 4.2928 19.8228 3.86592 19.3959C3.43904 18.969 3.19922 18.39 3.19922 17.7863V15.2251C3.19922 15.0262 3.1202 14.8354 2.97955 14.6948C2.8389 14.5541 2.64813 14.4751 2.44922 14.4751C2.25031 14.4751 2.05954 14.5541 1.91889 14.6948C1.77824 14.8354 1.69922 15.0262 1.69922 15.2251V17.7863C1.70021 18.7876 2.09838 19.7475 2.80635 20.4555C3.51432 21.1634 4.47425 21.5616 5.47547 21.5626H18.5255C19.5267 21.5616 20.4866 21.1634 21.1946 20.4555C21.9026 19.7475 22.3007 18.7876 22.3017 17.7863V15.2251C22.3017 15.0262 22.2227 14.8354 22.082 14.6948C21.9414 14.5541 21.7506 14.4751 21.5517 14.4751Z"
        fill="#323232"
      />
      <path
        d="M11.4673 16.665C11.537 16.7353 11.62 16.7911 11.7114 16.8292C11.8028 16.8672 11.9008 16.8868 11.9998 16.8868C12.0988 16.8868 12.1968 16.8672 12.2882 16.8292C12.3796 16.7911 12.4626 16.7353 12.5323 16.665L16.7998 12.3975C16.9189 12.2542 16.9803 12.0718 16.9723 11.8856C16.9642 11.6995 16.8872 11.5231 16.7561 11.3906C16.6251 11.2582 16.4495 11.1793 16.2635 11.1692C16.0774 11.1592 15.8943 11.2187 15.7498 11.3363L12.7498 14.3363V3.1875C12.7498 2.98859 12.6708 2.79782 12.5301 2.65717C12.3895 2.51652 12.1987 2.4375 11.9998 2.4375C11.8009 2.4375 11.6101 2.51652 11.4695 2.65717C11.3288 2.79782 11.2498 2.98859 11.2498 3.1875V14.325L8.24979 11.325C8.10906 11.1843 7.91819 11.1052 7.71917 11.1052C7.52014 11.1052 7.32927 11.1843 7.18854 11.325C7.04781 11.4657 6.96875 11.6566 6.96875 11.8556C6.96875 12.0546 7.04781 12.2455 7.18854 12.3862L11.4673 16.665Z"
        fill="#323232"
      />
    </svg>
  );
}
