import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faChevronCircleLeft } from "@fortawesome/pro-light-svg-icons/faChevronCircleLeft";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons/faChevronCircleRight";
import { faBars } from "@fortawesome/pro-light-svg-icons/faBars";
import { faBook } from "@fortawesome/pro-light-svg-icons/faBook";
import { faCalculator } from "@fortawesome/pro-light-svg-icons/faCalculator";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons/faCircleCheck";
import { faCircleUser } from "@fortawesome/pro-light-svg-icons/faCircleUser";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons/faExclamationTriangle";
import { faHandshake } from "@fortawesome/pro-light-svg-icons/faHandshake";
import { faHeart } from "@fortawesome/pro-light-svg-icons/faHeart";
import { faLightbulb } from "@fortawesome/pro-light-svg-icons/faLightbulb";
import { faNewspaper } from "@fortawesome/pro-light-svg-icons/faNewspaper";
import { faPallet } from "@fortawesome/pro-light-svg-icons/faPallet";
import { faPencil } from "@fortawesome/pro-light-svg-icons/faPencil";
import { faPhone } from "@fortawesome/pro-light-svg-icons/faPhone";
import { faQuestion } from "@fortawesome/pro-light-svg-icons/faQuestion";
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch";
import { faSeedling } from "@fortawesome/pro-light-svg-icons/faSeedling";
import { faShoppingBag } from "@fortawesome/pro-light-svg-icons/faShoppingBag";
import { faShovel } from "@fortawesome/pro-light-svg-icons/faShovel";
import { faSparkles } from "@fortawesome/pro-light-svg-icons/faSparkles";
import { faStar } from "@fortawesome/pro-light-svg-icons/faStar";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faUser } from "@fortawesome/pro-light-svg-icons/faUser";
import { faXmark } from "@fortawesome/pro-light-svg-icons/faXmark";
import { faCalendarXmark } from "@fortawesome/pro-light-svg-icons/faCalendarXmark";
import { faCircle } from "@fortawesome/pro-light-svg-icons/faCircle";
import { faCircleXmark } from "@fortawesome/pro-light-svg-icons/faCircleXmark";
import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faFileUpload } from "@fortawesome/pro-light-svg-icons/faFileUpload";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons/faTrashAlt";
import { faHourglass } from "@fortawesome/pro-light-svg-icons/faHourglass";
import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FC } from "react";

export type FaIconComponentProps = Omit<FontAwesomeIconProps, "icon">;

function createFaIconComponent(icon: IconDefinition): FC<FaIconComponentProps> {
  return function (props: FaIconComponentProps) {
    return <FontAwesomeIcon icon={icon} {...props} size={props.size || "1x"} />;
  };
}

export const FaIcon = (function () {
  const faIcon = {
    faHourglass,
    faTrashAlt,
    faFileUpload,
    faCheck,
    faCircle,
    faCircleXmark,
    faHeart,
    faExclamationTriangle,
    faCircleCheck,
    faXmark,
    faBars,
    faCircleUser,
    faCalculator,
    faTimes,
    faSearch,
    faPhone,
    faInstagram,
    faHandshake,
    faLightbulb,
    faNewspaper,
    faStar,
    faShoppingBag,
    faQuestion,
    faSparkles,
    faPallet,
    faShovel,
    faBook,
    faSeedling,
    faPencil,
    faUser,
    faChevronCircleLeft,
    faChevronCircleRight,
    faCalendarXmark,
  } as const;

  type FontAwesomeIconComponents = {
    [Property in keyof typeof faIcon]: FC<FaIconComponentProps>;
  };

  const iconComponents: Record<string, FC<FaIconComponentProps>> = {};

  for (const key in faIcon) {
    iconComponents[key] = createFaIconComponent(
      faIcon[key as keyof typeof faIcon],
    );
  }
  return iconComponents as FontAwesomeIconComponents;
})();
