"use client";

import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import {
  InvalidCredentialsException,
  getAccessToken,
  isInvalidCredentialsException,
} from "./getAccessToken";
import { inBrowser } from "./inBrowser";

export function GQLClient(
  handleInvalidCredentials = defaultInvalidCredentialsHandler,
) {
  const httpLink = createHttpLink({
    uri: "/api/graphql",
  });

  const authLink = setContext(async (_, { headers }) => {
    let token: string | undefined;

    try {
      token = await getAccessToken();
    } catch (error) {
      if (isInvalidCredentialsException(error)) {
        handleInvalidCredentials(error);
      }
    }

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: shouldConnectToApolloDevTools(),
  });
}

function shouldConnectToApolloDevTools() {
  return (
    inBrowser() &&
    (window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1")
  );
}

/**
 * Default handler for invalid credentials. Do not use on eden
 */
export function defaultInvalidCredentialsHandler(
  error: InvalidCredentialsException,
): void {
  const url = new URL("/login", window.location.origin);
  url.searchParams.set("error", error.error);
  url.searchParams.set("callbackUrl", window.location.pathname);
  url.searchParams.set("logout", "true");
  window.location.replace(url.toString());
}
