import { ReactNode } from "react";

export interface ArrowRightProps {
  width?: string;
  height?: string;
  className?: string;
  fill?: string;
}

export function ArrowRight(props: ArrowRightProps): ReactNode {
  const { width, height, fill } = props;
  return (
    <svg
      width={width ? width : "24"}
      height={height ? height : "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8787 12L10.9393 6.06066C10.3536 5.47487 10.3536 4.52513 10.9393 3.93934C11.5251 3.35355 12.4749 3.35355 13.0607 3.93934L20.0607 10.9393C20.6464 11.5251 20.6464 12.4749 20.0607 13.0607L13.0607 20.0607C12.4749 20.6464 11.5251 20.6464 10.9393 20.0607C10.3536 19.4749 10.3536 18.5251 10.9393 17.9393L16.8787 12Z"
        fill={fill ? fill : "#C7C8CD"}
      />
    </svg>
  );
}
