import { ReactNode } from "react";

export interface TooltipInfoProps {
  width?: string;
  height?: string;
  className?: string;
  fill?: string;
}

export function TooltipInfo(props: TooltipInfoProps): ReactNode {
  const { width, height, fill, className = "" } = props;
  return (
    <svg
      width={width ? width : "18"}
      height={height ? height : "16"}
      viewBox="0 0 18 16"
      className={className}
      fill={fill ? fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM7.75 10.5H8.5V8.5H7.75C7.3125 8.5 7 8.1875 7 7.75C7 7.34375 7.3125 7 7.75 7H9.25C9.65625 7 10 7.34375 10 7.75V10.5H10.25C10.6562 10.5 11 10.8438 11 11.25C11 11.6875 10.6562 12 10.25 12H7.75C7.3125 12 7 11.6875 7 11.25C7 10.8438 7.3125 10.5 7.75 10.5ZM9 6C8.4375 6 8 5.5625 8 5C8 4.46875 8.4375 4 9 4C9.53125 4 10 4.46875 10 5C10 5.5625 9.53125 6 9 6Z"
        fill="black"
      />
    </svg>
  );
}
