"use client";

import { getSession } from "next-auth/react";
import { inBrowser } from "./inBrowser";

interface TokenCacheEntry {
  token: string;
  expiration: number;
}

const TOKEN_CACHE_KEY = "_auth_token_cache";

/**
 * Exception thrown when credentials are invalid
 */
export class InvalidCredentialsException extends Error {
  constructor(public readonly error: string) {
    super("Invalid credentials");
  }
}

export function isInvalidCredentialsException(
  error: unknown,
): error is InvalidCredentialsException {
  return error instanceof InvalidCredentialsException;
}

/**
 * Gets a yardzen access token from session cache or next-auth route.
 *
 * redirects to login page if credentials are invalid (refresh token expired or revoked)
 *
 * @throws InvalidCredentialsException if credentials are invalid
 */
export async function getAccessToken(): Promise<string | undefined> {
  let token = getCachedToken();
  token ||= await getTokenFromAuth();

  return token;
}

async function getTokenFromAuth(): Promise<string | undefined> {
  const auth = (await getSession()) as {
    idToken?: string;
    error?: string;
  } | null;
  if (!inBrowser()) {
    return undefined;
  }
  if (auth?.error) {
    throw new InvalidCredentialsException(auth?.error);
  }
  if (auth?.idToken) {
    setCachedToken(auth.idToken);
  }

  return auth?.idToken;
}

function setCachedToken(token: string) {
  const expiration = Date.now() + 10 * 1000; // 10 seconds
  const cache: TokenCacheEntry = { token, expiration };

  window.sessionStorage.setItem(TOKEN_CACHE_KEY, JSON.stringify(cache));
}

export function clearTokenCache() {
  window.sessionStorage.removeItem(TOKEN_CACHE_KEY);
}

function getCachedToken(): string | undefined {
  let cache: TokenCacheEntry;

  try {
    const _cache = window.sessionStorage.getItem(TOKEN_CACHE_KEY);
    if (!_cache) {
      return undefined;
    }

    cache = JSON.parse(_cache);
  } catch (error) {
    console.error("Error parsing token cache", error);
    window.sessionStorage.removeItem(TOKEN_CACHE_KEY);
    return undefined;
  }

  if (cache.expiration > Date.now()) {
    return cache.token;
  } else {
    window.sessionStorage.removeItem(TOKEN_CACHE_KEY);
  }

  return undefined;
}
