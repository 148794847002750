"use client";

/**
 * Returns true if the code is running in a browser environment.
 *
 * Nextjs client components do a shallow pre-render on the server, this
 * can be used to determine whether the code is running in an actual browser
 * environment.
 */
export function inBrowser() {
  return typeof window !== "undefined";
}
