import { ReactNode } from "react";

export interface CalendarProps {
  width?: string;
  height?: string;
  className?: string;
  fill?: string;
}

export function Calendar(props: CalendarProps): ReactNode {
  const { width, height, fill } = props;
  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "22"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.4 7.98779H20.8875H21.6C21.8625 7.98779 22.125 8.06279 22.3875 8.17529V6.45029C22.3875 4.76279 21.0375 3.37529 19.35 3.30029V4.80029V5.02529C19.35 5.43779 19.0125 5.77529 18.6 5.77529C18.1875 5.77529 17.85 5.40029 17.85 5.02529C17.85 4.95029 17.85 4.87529 17.85 4.80029C17.85 4.31279 17.85 3.82529 17.85 3.30029H8.5875V4.80029V4.95029C8.5875 5.36279 8.25 5.70029 7.8375 5.70029C7.425 5.70029 7.0875 5.32529 7.0875 4.95029C7.0875 4.91279 7.0875 4.83779 7.0875 4.80029C7.0875 4.76279 7.0875 4.76279 7.0875 4.72529C7.0875 4.23779 7.0875 3.78779 7.0875 3.30029C5.3625 3.30029 3.9375 4.72529 3.9375 6.45029V8.96279C4.1625 8.36279 4.725 7.98779 5.4 7.98779Z"
        fill={fill ? fill : "white"}
      />
      <path
        d="M20.8875 17.9625C20.3625 19.0125 19.3125 19.65 18.1875 19.65H5.8125H4.125C4.575 20.8875 5.7375 21.75 7.0875 21.75H19.2375C20.9625 21.75 22.3875 20.325 22.3875 18.6V14.0625C22.275 14.475 22.125 14.925 21.975 15.3375C21.675 16.2 21.3 17.1 20.8875 17.9625Z"
        fill={fill ? fill : "white"}
      />
      <path
        d="M21.6 9.48779H5.39999C5.32499 9.48779 5.28749 9.52529 5.28749 9.60029C5.06249 11.4378 4.61249 13.2378 4.01249 14.9628C3.71249 15.7503 3.37499 16.5753 2.99999 17.3628C2.84999 17.7003 3.07499 18.1503 3.48749 18.1503H18.225C18.7875 18.1503 19.3125 17.8128 19.575 17.3253C19.95 16.5378 20.2875 15.6753 20.5875 14.8503C21.1875 13.1628 21.5625 11.4003 21.75 9.63779C21.75 9.60029 21.75 9.56279 21.7125 9.52529C21.675 9.48779 21.6375 9.48779 21.6 9.48779ZM15.9 12.5253C14.8875 13.5378 13.875 14.5503 12.825 15.6003C12.525 15.9003 12.0375 15.9003 11.775 15.6003C11.0625 14.8878 10.35 14.1753 9.6375 13.4628C8.96249 12.7878 10.0125 11.7003 10.6875 12.4128C11.2125 12.9378 11.775 13.5003 12.3 14.0253C13.1625 13.1628 13.9875 12.3378 14.85 11.4753C15.525 10.8003 16.575 11.8503 15.9 12.5253Z"
        fill={fill ? fill : "white"}
      />
    </svg>
  );
}
